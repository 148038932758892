.view_modal {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-height: 80vh;  /* Adjusted for better vertical spacing */
    padding: 2rem;
    width: 80vw;
    overflow-y: auto !important;
    position: relative;
  }
  
  .view_table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  .view_table > .tr,
  .view_table > .td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .table_head tr th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .table_body tr td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .image_container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 1px solid #000;
  }
  
  .thumbnail {
    width: 150px;
    height: 100px;
    object-fit: cover;
    border: 1px solid #000;
  }
  
  .description {
    max-height: 160px;
    overflow-y: auto;
  }
  
  /* New styles for sticky header */
  .modal_header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
    padding-bottom: 1rem;
  }
  