.main_div {
  max-height: 80vh;
  overflow-y: auto !important;
  margin-top: 1.5rem;
  width: 50%;
  padding: 2rem;
  border-radius: 5px;
  background-color: white;
}

/* Apply overflow-y: scroll if the content exceeds the maximum height */
.main_div {
  overflow-y: scroll;
}

.error_msg {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
  color: #d32f2f;
}
